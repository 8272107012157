import * as React from "react";
import "../styles/main.scss";
import { Layout } from "../components";
import { Link } from "gatsby";

const IndexPage = () => {
  return (
    <Layout>
      <main className="columns section">
        <div className="column ">
          <figure className="image is-16by9">
            <iframe
              title="Welcome Video"
              className="has-ratio"
              width="640"
              height="360"
              src="https://www.youtube-nocookie.com/embed/YE7VzlLtp-4"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </figure>
        </div>
        <div className="column content">
          <p>
            My name is Steve Rollason and I am the founder and owner of Mindmap.
          </p>
          <p>
            At Mindmap we specialise in career and personal development coaching{" "}
            <Link to="services">services</Link>.
          </p>
          <p>
            At Mindmap we can help you reach the next level of your career and
            we can support/ develop you whether you are a graduate on your early
            career journey or looking to become the next senior leader.
          </p>
          <p>
            Don’t believe us? Have a look at our{" "}
            <Link to="testimonials">testimonials</Link> and read some client
            stories. We have helped clients from around the globe to develop
            into their best selves resulting in the achievement of their dream
            roles and furthering their careers.
          </p>
          <p>
            <Link to="contact">Contact us</Link> for a free consultation.
          </p>
        </div>
      </main>
    </Layout>
  );
};

export default IndexPage;
